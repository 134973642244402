export const baseUrl = {
    URL: process.env.NODE_ENV == 'development' ?
        // 'http://47.108.194.154:8086/api/v1/' : //林投测试环境
        // 'http://192.168.1.200:8060/api/v1/' : //谭鑫
        // 'http://192.168.1.26:8781/api/v1/': //余福
        // 'http://192.168.1.24:8781/api/v1/' : //王俊
        // 'http://wlw.sidwit.com:8081/api/v1/' :
        'http://api.sidwit.com/api/v1/' : //

        // 'http://wlw.sidwit.com:8081/api/v1/':
        // 'http://47.108.194.154:8086/api/v1/'://线上
        //  'http://120.79.100.138:8085/api/v1/':
        // 'http://47.115.47.175:8088/api/v1':
        'http://api.xd.sidwit.com/api/v1/',
        // 'http://api.xd.sidwit.com/api/v1/',
    OPEN: process.env.NODE_ENV == 'development' ?
        // 'http://192.168.1.20:8060/openapi/v2/' 
        'http://api.sidwit.com/openapi/v2/'  //王俊

        : 'http://api.xd.sidwit.com/openapi/v2/',
        // : 'http://api.xd.sidwit.com/openapi/v2/',
}

export const msgWSUrl =
    process.env.NODE_ENV === 'development' ?
        'ws://47.108.194.154:34567' :
        'ws://192.168.1.13:34567';

const API = {
    // 上传文件
    UPLOAD: {
        // 文件上传
        LOGGED_IN: 'fileupload/fileup',
        // 图片上传
        IMAGE: 'fileupload/upload',
        // 限制大小上传
        UPLOAD_LIMITE: 'fileupload/filebywh',
    },

    // 通用
    COMMON: {
        // 下级公司树
        COMPANY: 'company/tree',
        // 查询字典
        DICT: 'dict/list',
        // 墨迹天气预报
        PREWEATHER: 'common/meteorological/weather',
        //登录日志
        LOGIN_LOG: 'logmsg/loginlist',
        //操作日志
        OPERATION_LOG: 'logmsg/operationlist',
        // 注册
        REGIST: 'register',
        // 发送验证码
        VERIFICATION_CODE: 'common/sendcode',
        // 验证验证码
        CHECK_CODE: 'common/checksmscode',
        // 重置密码
        RESET_PASSWORD: 'apiapply/changepwd',
        // 天气查询
        WEATHER: 'common/meteorological/weather'
    },
    // 个人相关
    USER: {
        // 登录
        LOGIN: 'login',
        // 用户详情
        INFO: 'user/view',
        // 登出
        LOGOUT: 'logout',
        // 手机发送验证码
        CODE_SEND: 'common/sendsms',
        // 人脸识别
        FACE_RECON: 'face/facesearch',
        // 查询权限
        AUTHORITY: 'user/menuList',
        // 新版查询菜单
        MENU: 'applymenu/treelist',
        // 新版查询用户平台列表
        APPLICATION: 'applymenu/applylist',
        // 查询用户平台菜单权限
        APPLYMENU: 'applymenu/treelist',
        //登录页背景图片
        USER_LOGIN_INFO_IMG: 'adv/listbycode',
        // 根据token获取登录信息
        USER_INFO_BY_TOKEN: '',
        //物联网一张图菜单
        IOT_MENU: 'applymenu/companylisttree'
    },

    // 用户管理
    USER_MANAGE: {
        // 设置用户角色
        SET_ROLE: 'user/setRole',
        // 用户列表
        USER_LIST: 'user/list',
        // 用户详情
        USER_VIEW: 'user/view',
        // 添加用户
        USER_ADD: 'user/add',
        // 修改用户
        USER_EDIT: 'user/edit',
        // 删除用户
        DELETE: 'user/delete',
        // 修改用户状态
        STATUS_EDIT: 'user/updatestatus',
        // 清除设备授权码
        CLEARCODE: 'user/deleteauthcode'
    },

    // 用户部门管理
    DEPT_MANAGE: {
        // 列表
        LIST: 'branch/list',
        // 树形
        TREE: 'branch/tree',
        // 下移
        MOVEDOWN: 'branch/movedown',
        // 上移
        MOVEUP: 'branch/moveup',
        // 删除
        DELETE: 'branch/delete',
        // 停用
        STOP: 'branch/stop',
        // 启用
        OPEN: 'branch/open',
        // 详情
        VIEW: 'branch/view',
        // 修改
        EDIT: "branch/edit",
        // 添加
        ADD: 'branch/add',
    },
    // 角色管理
    ROLE: {
        // 列表
        LIST: 'role/list',
        // 添加
        ADD: "role/add",
        // 修改
        EDIT: 'role/edit',
        // 删除
        DELETE: 'role/delete',
        // 提交
        UPDATE: 'role/updatestatus',
        // 设置操作权限
        CA: 'role/setAuthority',
        // 设置数据权限
        DA: 'role/setDataAuthority',
        // 获取操作权限树
        GET_AU_TREE: 'role/menuTreeListByRoleId',
        // 获取数据权限
        GET_DATA_AU: 'role/roleDataListByRoleId'
    },

    //配置管理
    CONFIG_MANAGE: {
        //配置管理列表
        CONFIG_LIST: 'ltconfig/list',
        //配置管理属性
        CONFIG_CHILD: 'ltconfig/childlist',
        // 添加类型
        CONFIG_ADD: 'ltconfig/add',
        // 编辑类型
        CONFIG_EDIT: 'ltconfig/update',
        // 删除类型
        CONFIG_DELETE: 'ltconfig/delete',

        //区域--新增
        AREA_ADD: 'region/add',
        //区域--修改
        AREA_EDIT: 'region/edit',
        //区域--删除
        AREA_DELETE: 'region/delete',
        //区域--列表
        AREA_LIST: 'region/list',
        //
        AREA_CHILD: 'region/child'
    },

    // 物料类型
    CATEGORY: {
        LIST: 'category/list',
        // 商品类别
        CLIST: 'category/listexpand',
        ADD: 'category/add',
        EDIT: 'category/edit',
        DELETE: 'category/delete',
        STATUS: 'category/updatestatus',
        VIEW: 'category/view'
    },
    // 物料属性
    ATTRIBUTE: {
        LIST: 'productproperty/list',
        ADD: 'productproperty/add',
        EDIT: 'productproperty/edit',
        DELETE: 'productproperty/delete',
        STATUS: 'productproperty/updatestatus',
        VIEW: 'productproperty/view'
    },
    // 物料
    PRODUCT: {
        LIST: 'product/lists',
        ADD: 'product/add',
        EDIT: 'product/edit',
        DELETE: 'product/delete',
        STATUS: 'product/updatestatus',
        VIEW: 'product/view',
        // 管理属性
        SET_ATTRIBUTE: 'productrelation/add',
        // 物料属性列表
        ATTRIBUTE: 'productrelation/list'
    },

    GOODS: {
        LIST: 'productexpand/list',
        ADD: 'productexpand/add',
        EDIT: 'productexpand/edit',
        STATUS: 'productexpand/updatestatus',
        DELETE: 'productexpand/delete'
    },

    //品牌管理
    BRAND: {
        LIST: 'productBrand/list',
        ADD: 'productBrand/add',
        EDIT: 'productBrand/edit',
        DELETE: 'productBrand/delete',
        STATUS_EDIT: 'productBrand/updatestatus'
    },

    // 商品类别
    GOODS_TYPE: {
        // 系统标准类别
        SYSTEM_GOODS_TYPES: "category/listgoods"
    },

    // 供应商
    SUPPLIER: {
        LIST: 'suppliers/list',
        ADD: 'suppliers/add',
        EDIT: 'suppliers/edit',
        DELETE: 'suppliers/delete'
    },
    // 供应商货品
    SUPPLYGOODS: {
        ADD: 'supplierproduct/add',
        EDIT: 'supplierproduct/edit',
        DELETE: 'supplierproduct/delete',
        LIST: 'supplierproduct/list'
    },

    // 仓库管理
    WAREHOUSE: {
        LIST: 'warehouse/list',
        ADD: 'warehouse/add',
        EDIT: 'warehouse/edit',
        DELETE: 'warehouse/delete',
        STATUS: 'warehouse/updatestatus'
    },

    // 采购管理
    PURCHASE: {
        ADD: 'purchase/add',
        EDIT: 'purchase/edit',
        DELETE: 'purchase/delete',
        LIST: 'purchase/list',
        LISTS: 'purchase/listdetails',
        VIEW: 'purchase/details',
        UPDATE: 'purchase/updatestatus',
        STOREIN: 'purchase/addInWarehouse'
    },

    // 库存管理
    STORAGE: {
        // 库存列表
        GROUP: 'traceStockdetail/listgroup',
        // 库存列表
        LIST: 'tracestockdetail/list',
        // 变动记录
        CHANGE: 'tracestockchange/list',
        // 入库记录
        STORE_IN_RECORD: 'traceStockin/list',
        STORE_IN_RECORD_DETAIL: 'traceStockin/listdetails',
        // 出库记录
        STORE_OUT_RECORD_DETAIL: 'tracestockout/listdetails',
    },

    //消息管理
    NEWS_MANAGE: {
        //公告消息
        ANNOUNCEMENT_LIST: 'ltcommonnotice/list',
        ANNOUNCEMENT_ADD: 'ltcommonnotice/add',
        ANNOUNCEMENT_DELETE: 'ltcommonnotice/delete',
        ANNOUNCEMENT_SEND: 'ltcommonnotice/send',
        ANNOUNCEMENT_VIEW: 'ltcommonnotice/view',
        ANNOUNCEMENT_EDIT: 'ltcommonnotice/edit',
        //业务消息
        BUSINESS_LIST: 'ltcommonmessage/list',
        BUSINESS_ADD: 'ltcommonmessage/add',
        BUSINESS_EDIT: 'ltcommonmessage/edit',
        BUSINESS_DELETE: 'ltcommonmessage/delete',
        BUSINESS_VIEW: 'ltcommonmessage/view',
        BUSINESS_SEND: 'ltcommonmessage/send',
        BUSINESS_READ: 'ltcommonmessage/read'
    },

    //新闻分类
    NEWS_CLASS: {
        LIST: 'newscat/list',
        LIST_TREE: 'newscat/listtree',
        ADD: 'newscat/add',
        EDIT: 'newscat/edit',
        DELETE: 'newscat/delete'
    },

    //新闻
    NEWS: {
        LIST: 'news/list',
        ADD: 'news/add',
        EDIT: 'news/edit',
        DELETE: 'news/delete',
        EDIT_STATUS: 'news/editstatus',
        INFO: 'news/info'
    },

    //圈子
    CIRCLE: {
        LIST: 'circle/list',
        ADD: 'circle/add',
        DELETE: 'circle/delete',
        UPDATE: 'circle/update',
        VIEW: 'circle/view',
        OPEN: 'circle/open',
        STOP: 'circle/stop'
    },

    //圈子用户
    CIRCLE_USER: {
        LIST: 'circleuser/list',
        ADD: 'circleuser/add',
        DELETE: 'circleuser/delete',
        UPDATE: 'circleuser/update',
        VIEW: 'circleuser/view',
        // OPEN: 'circleuser/open',
        // STOP: 'circleuser/stop'
    },

    //话题
    TOPIC: {
        LIST: 'topic/list',
        ADD: 'topic/add',
        DELETE: 'topic/delete',
        UPDATE: 'topic/update',
        VIEW: 'topic/view',
        OPEN: 'topic/open',
        STOP: 'topic/stop',
    },

    // 资料管理
    DATA_CENTER: {
        // 分类列表（树形）
        PEST_CLASS_TREE: 'pestcat/listtree',
        // 分类列表（非树形）
        PEST_CLASS_LIST: 'pestcat/list',
        // 分类新增
        PEST_CLASS_NEW: 'pestcat/add',
        // 分类编辑
        PEST_CLASS_EDIT: 'pestcat/edit',
        // 分类删除
        PEST_CLASS_DEL: 'pestcat/delete',
        PEST_CLASS_STATUS: 'pestcat/editstatus',
        // 列表
        PEST_LIST: 'pest/list',
        NEW_PEST_LIST: 'pest/basiclist',
        // 修改
        PEST_EDIT: 'pest/edit',
        // 添加
        PEST_NEW: 'pest/add',
        // 删除
        PEST_DEL: 'pest/delete',
        //详细信息
        PEST_INFO: 'pest/info'
    },

    //课程分类
    COURSE_CATEGORY: {
        LIST: 'studycategory/list',
        LIST_TREE: 'studycategory/listtree',
        ADD: 'studycategory/add',
        DELETE: 'studycategory/delete',
        UPDATE: 'studycategory/update',
    },

    //课程
    COURSE: {
        LIST: 'studycourse/list',
        ADD: 'studycourse/add',
        UPDATE: 'studycourse/update',
        VIEW: 'studycourse/view',
        DELETE: 'studycourse/delete',
        OPEN: 'studycourse/open',
        STOP: 'studycourse/stop'
    },

    //专家
    EXPERT: {
        LIST: 'sysexpert/list'
    },

    //学习设计
    STUDY_PROCESS: {
        LIST: 'studyprocess/list',
        ADD: 'studyprocess/add',
        UPDATE: 'studyprocess/edit',
        DELETE: 'studyprocess/delete',
        OPEN: 'studyprocess/open',
        STOP: 'studyprocess/stop',
        VIEW: 'studyprocess/view'
    },
    // 基地
    FARM: {
        LIST: 'farm/list',
        LIST_TREE: 'farm/listdetail', // 带场景树形结构
        ADD: 'farm/add',
        EDIT: 'farm/edit',
        DELETE: 'farm/delete',
        VIEW: 'farm/view',

        SCENE_ADD: 'farmscrene/add',
        SCENE_VIEW: 'farmscrene/view',
        SCENE_EDIT: 'farmscrene/edit',
        SCENE_DEL: 'farmscrene/delete',
        SCENE_LIST: 'farmscrene/list',

        // 基地-生产区域-地块
        BASE_PRODUCT_AREA_TREE: 'massif/farmlist',
        // 添加基地生产区域
        BASE_PRODUCT_AREA_ADD: 'farmblock/add',
        // 编辑基地生产区域
        BASE_PRODUCT_AREA_EDIT: 'farmblock/edit',
        // 删除基地生产区域
        BASE_PRODUCT_AREA_DEL: 'farmblock/delete',
        // 添加基地生产区域-地块
        BASE_PRODUCT_AREA_BLOCK_ADD: 'massif/add',
        // 编辑基地生产区域-地块
        BASE_PRODUCT_AREA_BLOCK_EDIT: 'massif/edit',
        // 删除基地生产区域-地块
        BASE_PRODUCT_AREA_BLOCK_DEL: 'massif/delete',

        // 基地生产区域列表
        BASE_PRODUCT_AREA_LIST: 'farmblock/list',
        // 基地生产区域-地块列表
        BASE_PRODUCT_AREA_BLOCK_LIST: 'massif/list',

        // 基地地理信息详情（含生产区-地块）
        BASE_AREA_BLOCK_INFO: 'sfjarea/listfsa',

        //管道列表
        PIPELINE_LIST: 'devicesfjchannel/farmlist',
        //管道树
        PIPELINE_TREE: 'devicesfjchannel/treelist',
        //管道添加
        PIPELINE_ADD: 'devicesfjchannel/add',
        //管道修改
        PIPELINE_EDIT: 'devicesfjchannel/edit',
        //管道删除
        PIPELINE_DELETE: 'devicesfjchannel/delete',
        //管道详情
        PIPELINE_VIEW: 'devicesfjchannel/view',
        //管道启用
        PIPELINE_OPEN: 'devicesfjchannel/openoperation',
        //管道停用
        PIPELINE_STOP: 'devicesfjchannel/stopoperation',

        // 按条件查询基地场景树形数据
        BASE_SCENE_TREE_BY_QUERY: 'farmscrene/listtreebytype',

        //历史数据
        HISTORY_DATA: 'farmrecod/exportlist',
    },

    // 场景通道相关
    AISLE: {
        // 获取场景通道列表
        AISLE_LIST: 'farmchannel/list',
        // 编辑场景通道
        AISLE_EDIT: 'farmchannel/edit',
        // 新增场景通道
        AISLE_NEW: 'farmchannel/add',
        // 删除场景通道
        AISLE_DEL: 'farmchannel/delete',
        // 聚英板卡通道列表
        JY_AISLELIST: 'jycarddoconfig/list',
        // 聚英板卡通道新增
        JY_AISLENEW: 'jycarddoconfig/add',
        // 聚英板卡通道编辑
        JY_AISLEEDIT: 'jycarddoconfig/edit',
        // 聚英板卡通道删除
        JY_AISLEDEL: 'jycarddoconfig/delete',
        // 四八五板卡通道列表
        SBW_AISLELIST: 'xphdevicechannel/list',
        // 四八五板卡通道新增
        SBW_AISLENEW: 'xphdevicechannel/add',
        // 四八五板卡通道编辑
        SBW_AISLEEDIT: 'xphdevicechannel/edit',
        // 四八五板卡通道删除
        SBW_AISLEDEL: 'xphdevicechannel/delete',
    },

    // 智能灌溉
    SMART_IRRG: {
        // 水肥机列表
        WFM_LIST: 'sfjdevice/list',
        // 改变水肥机状态
        WFM_STATUS_CHANGE: 'sfjdevice/updatestatus',
        // 添加水肥机
        WFM_NEW: 'sfjdevice/add',
        // 编辑水肥机
        WFM_EDIT: 'sfjdevice/edit',
        // 删除水肥机
        WFM_DEL: 'sfjdevice/delete',
        // 水肥机实时数据分组列表
        WFM_REAL_DATA_GROUP: 'farmsensor/groupname/list',
        // 水肥机灌区列表
        WFM_AREA_LIST: 'sfjarea/list',
        // 水肥机灌区树状列表
        WFM_AREA_TREE: 'sfjarea/farmlist',
        // 水肥机灌区添加
        WFM_AREA_ADD: 'sfjarea/add',
        // 水肥机灌区编辑
        WFM_AREA_EDIT: 'sfjarea/edit',
        // 水肥机灌区删除
        WFM_AREA_DEL: 'sfjarea/delete',
        // 基地详情（含灌区列表）
        WFM_BASE_DETAIL: 'sfjarea/farmview',
        // 水肥机策略列表（定量，轮灌）
        WFM_POLY_LIST: 'sfjstrategy/list',
        // 水肥机策停用
        WFM_POLY_GROUP_STOP: 'sfjstrategy/stop',
        // 水肥机策启用
        WFM_POLY_GROUP_OPEN: 'sfjstrategy/open',

        // 水肥灌区tree根据水肥机id（下级含设备）
        WFM_AREA_TREE_BY_WFMID: 'sfjarea/listarea',

        // 添加定量、灌溉策略
        WFM_DLGG_ADD: 'sfjstrategy/addinfo',
        // 编辑定量、灌溉策略
        WFM_DLGG_EDIT: 'sfjstrategy/editinfo',

        // 水肥机策略列表（定量，轮灌）
        WFM_POLY_LIST_GROUP: 'sfjstrategy/listgroup',
        // 水肥机策略删除
        WFM_POLY_GROUP_DEL: 'sfjstrategy/delinfo',

        // 水肥机虚拟设备打开
        WFM_XN_DEVICE_ON: 'sfjareadevice/openoperation',
        // 水肥机虚拟设备关闭
        WFM_XN_DEVICE_OFF: 'sfjareadevice/stopoperation',

        // 水肥机灌区操作打开
        WFM_AREA_ON: 'sfjarea/openoperation',
        // 水肥机灌区操作关闭
        WFM_AREA_OFF: 'sfjarea/stopoperation',

        //精量配比
        RATIO_LIST: 'sfjratio/list',
        RATIO_ADD: 'sfjratio/editinfo',
        RATIO_VIEW: 'sfjratio/view'
    },

    // 一体机、设备相关模块
    DEVICES: {
        // 一体机列表
        YTJLIST: 'aiodevice/list',
        // 新增一体机
        YTJNEW: 'aiodevice/add',
        // 一体机状态修改
        YTJSTATUSCHANGE: 'aiodevice/updatestatus',
        // 一体机删除
        YTJDEL: 'aiodevice/delete',
        // 一体机编辑
        YTJEDIT: 'aiodevice/edit',
        // 聚英板卡列表
        JYLIST: 'jycarddevice/list',
        // 新增聚英板卡
        JYNEW: 'jycarddevice/add',
        // 聚英板卡修改状态
        JYSTATUSCHANGE: 'jycarddevice/updatestatus',
        // 删除聚英板卡
        JYDEL: 'jycarddevice/delete',
        // 编辑聚英板卡
        JYEDIT: 'jycarddevice/edit',
        // 845列表
        SBWLIST: 'xphdevice/list',
        // 485设备新增
        SBWNEW: 'xphdevice/add',
        // 485设备编辑
        SBWEDIT: 'xphdevice/edit',
        // 485设备状态修改
        SBWSTATUSCHANGE: 'xphdevice/updatestatus',
        // 485设备删除
        SBWDEL: 'xphdevice/delete',
        // 罗拉设备列表
        LUOLA_LIST: 'luola/update',
        // 罗拉设备编辑
        LUOLA_EDIT: 'luola/edit',
        // 罗拉设备新增
        LUOLA_NEW: 'luola/add',
        // 罗拉设备删除
        LUOLA_DEL: 'luola/delete',
        // 罗拉设备状态更改
        LUOLA_STATUS_CHANGE: 'luola/updatestatus',

        // 设备告警列表
        DEVICE_ALARM: 'farmalarm/list',
    },

    DEVICE_LL: {
        // 设备管理列表
        ADD: 'devproduct/add',
        LIST: 'devproduct/list',
        DELETE: 'devproduct/delete',
        EDIT: 'devproduct/edit',
        INFO: 'devproduct/view',

        // 设备配置
        CONFIG_ADD: 'productconfig/add',
        CONFIG_EDIT: 'productconfig/edit',
        CONFIG_DETAIL: 'productconfig/info',
        // 设备授权
        AUTHORIZATION_LIST: 'devproauth/list',
        AUTHORIZATION_ADD: 'devproauth/add',
        AUTHORIZATION_EDIT: 'devproauth/edit',
        AUTHORIZATION_VIEW: 'devproauth/view',
        AUTHORIZATION_DELETE: 'devproauth/delete',
        AUTHORIZATION_BINDING: 'devproauth/authdevice',

        // 设备分类列表
        AUTHORIZATION_CLASS: 'devproauth/getdevicetype',

        // 传感器值范围列表
        MONI_RANGE_LIST: 'categoryrange/list',
        // 传感器值范围新增
        MONI_RANGE_ADD: 'categoryrange/add',
        // 传感器值范围删除
        MONI_RANGE_DEL: 'categoryrange/delete',
        // 传感器值范围编辑
        MONI_RANGE_EDIT: 'categoryrange/update',
        // 传感器值范围启用
        MONI_RANGE_OPEN: 'categoryrange/open',
        // 传感器值范围停用
        MONI_RANGE_STOP: 'categoryrange/stop',
    },

    MAP: {
        // 获取地图定位等配置信息
        MAP_CFG_INFO: 'common/maplocation/list',
        // 根据省市code码获取边界
        GET_BOUNDARY_BY_CODE: 'common/mappoint/list',
    },

    // 个性配置相关
    PERCFG: {
        // 获取个性配置信息
        PERCFG_INFO: 'company/config/view',
        // 编辑个性配置信息
        PERCFG_EDIT: 'company/config/edit',
        // 获取版权
        COPYRIGHT: 'company/config/one'
    },

    // 虫情监测
    PEST_MONITOR: {
        DEVICE_LIST: 'insectdevice/list',
        DEVICE_INFO: 'insectdevice/devinfo',
        DEVICE_COLLECT_LIST: 'insectdevice/listinfo',
        DEVICE_PARAM_SET: 'insectdevice/setbase',
        PEST_MAX_SET: 'insectdevice/setbugmax',
        PEST_WARN_PHONE: 'insectdevice/setphone',
        PEST_ENV_SET: 'insectdevice/setenvir',
        PEST_CAPTURE: 'insectdevice/takepic',
        PEST_TURNOVER: 'insectdevice/remotetransfer',
        PEST_RESTART: 'insectdevice/restart',
        PEST_TURN_ON_OFF: 'insectdevice/light',
        PEST_SET_INFO: 'insectdevice/updatedev',
    },

    // 视频记录设备相关
    RECORD: {
        // 获取视频记录设备列表
        RECORD_LIST: 'farmvideo/list',
        // 添加视频记录设备
        RECORD_NEW: 'farmvideo/add',
        // 编辑视频记录设备
        RECORD_EDIT: 'farmvideo/edit',
        // 视频记录设备详情
        RECORD_DETAIL: 'farmvideo/view',
        // 删除视频记录设备
        RECORD_DEL: 'farmvideo/delete',
        // 更新监控设备状态
        RECORD_Status: 'farmvideo/updatestatus',
        // 视频图片抓拍策略列表
        RECORD_PHOTO_PLOY_LIST: 'videostrategy/list',
        // 新增视频图片抓拍策略
        RECORD_PHOTO_PLOY_NEW: 'videostrategy/add',
        // 编辑视频图片抓拍策略
        RECORD_PHOTO_PLOY_EDIT: 'videostrategy/edit',
        // 删除视频图片抓拍策略
        RECORD_PHOTO_PLOY_DEL: 'videostrategy/delete',
        // 视频图片抓拍策略状态更改
        RECORD_PHOTO_PLOY_STATUS_CHANGE: 'videostrategy/updatestatus',
        // 获取抓拍图片列表
        RECORD_PHOTO_LIST: 'videophoto/list',
        // 手动抓拍视频图片
        RECORD_PHOTO_HANDLER: 'videophoto/capture',
        // 获取视频预览url
        VIDEO_PREVIEW_URL: 'hkvideo/previewURLs',
        // 视频云平台操作
        VIDEO_CONTROLLING: 'hkvideo/controlling'
    },

    // 控制相关
    CONTROL: {
        // 控制设备分组列表
        CONTROL_GROUP: 'farmcontrol/grouplist',
        // 获取控制器列表
        CONTROL_LIST: 'farmcontrol/list',
        // 添加控制器
        CONTROL_NEW: 'farmcontrol/add',
        // 编辑控制器
        CONTROL_EDIT: 'farmcontrol/edit',
        // 删除控制设备
        CONTROL_DEL: 'farmcontrol/delete',
        // 更新控制设备开关状态
        CONTROL_SWITCH_STATUS: 'farmcontrol/switchstatus',
        // 更新控制设备锁定状态
        CONTROL_LOCK_STATUS: 'farmcontrol/lockstatus',

        // 新增控制开关（新）
        CONTROL_NEW2: 'farmcontrol/addlist',
        // 编辑控制开关（新）
        CONTROL_EDIT2: 'farmcontrol/edit2',

        // 控制器分类
        CONTROL_TYPE_LIST: 'farmcontrol/category/list',

        // 水肥机控制通道列表
        CONTROL_SFJ_LIST: 'sfjconfigdevice/list',

        // 水肥机多设备动作绑定
        CONTROL_SFJ_MUTIL_DEVICE_BD: 'channelconfig/addorupdate',

        // 查看水肥机多设备动作绑定设备
        CONTROL_SFJ_BD_INFO: 'channelconfig/details',

    },

    // 监测相关
    MONITOR: {
        // 获取监测传感器列表(分组树形)
        MONITOR_TREE: 'farmsensor/groupcategory/list',
        // 获取监测传感器列表(列表分页结构)
        MONITOR_LIST: 'farmsensor/list',
        // 编辑场景检测传感器
        MONITOR_EDIT: 'farmsensor/edit',
        // 添加场景监测传感器
        MONITOR_NEW: 'farmsensor/add',
        // 删除场景监测传感器
        MONITOR_DEL: 'farmsensor/delete',
        // 查看监测传感器详情
        MONITOR_DETAIL: 'farmsensor/view',
        // 获取场景传感器类别
        MONITOR_SENSORTYPE: 'GetCategoryPid',

        // 新增监测点（新）
        MONITOR_NEW2: 'farmsensor/add2',
        // 编辑监测点（新）
        MONITOR_EDIT2: 'farmsensor/edit2',

        // 传感器统计图表
        MONITOR_CHART: 'devicehour/listhour',
    },

    STA: {
        // 物联网首页设备数量统计
        DEVICES_STA: 'devproduct/getcount',
        // 生产首页统计
        PRODUCT_STA: 'summarizing/getall',
        // 园区巡查列表
        PATOL_LIST: 'dailypatrol/list',
        PRODUCT_DAILY: 'farmingdaily/list',
        TASK: 'farmingtask/list',
        PLAN: 'cropplan/list',
    },

    // 学习相关
    STUDY: {
        // 学习记录列表
        STUDY_LIST: 'studyuser/list',

        //根据角色分配
        STUDY_DISTRIBUTION: 'studyallocation/add',
        //根据学习课程分配
        STUDY_DISTRIBUTION_ADDPROCESS: 'studyallocation/addByCourseId',
        //分配列表
        STUDY_DISTRIBUTION_LIST: 'studyallocation/list'
    },
    //生产流程
    PROGRESS: {
        ADD: 'processinfo/add',
        EDIT: 'processinfo/update',
        DELETE: 'processinfo/delete',
        LIST: 'processinfo/list',
        DETAILS: 'processinfo/details'
    },

    // 流程节点
    PROGRESSNODE: {
        LIST: 'processnode/list',
        ADD: 'processnode/add',
        EDIT: 'processnode/update',
        DELETE: 'processnode/delete',
    },

    // 生产项目
    PROJECT: {
        ADD: 'processproject/add',
        EDIT: 'processproject/update',
        LIST: 'processproject/list',
        STOP: 'processproject/stop'
    },

    //运营项目
    OPERATE: {
        ADD: 'project/add',
        LIST: 'project/list',
        UPDATE: 'project/update',
        OPEN: 'project/open',
        STOP: 'project/stop',
        DELETE: 'project/delete',
    },


    //运营项目类别
    OPERATE_CATEGORY: {
        LIST: 'projectcategory/list',
        ADD: 'projectcategory/add',
        DELETE: 'projectcategory/delete',
        OPEN: 'projectcategory/open',
        STOP: 'projectcategory/stop',
        UPDATE: 'projectcategory/update',
    },

    //公司配置
    COMPANY_CONFIGURATION: {
        LIST: 'company/config/list',
        VIEW: 'company/config/view',
        EDIT: 'company/config/edit',
        COMPANY_LIST: "company/list",
        SEARCH: 'company/listn',
    },

    //平台轮播图
    SHUFFLING_FIGURE: {
        ADD: 'adv/add',
        LIST: 'adv/baselist',
        UPDATE: 'adv/update',
        DELETE: 'adv/delete',
        VIEW: 'adv/view',
    },

    // 生产任务
    TASK: {
        LIST: 'processtask/list',
        ADD: 'processtask/add',
        EDIT: 'processtask/update',
        DELETE: 'processtask/delete'
    },

    //问答管理
    QA_MANAGE: {
        LIST: 'question/list',
        Q_DELETE: 'question/delete',
        //获取话题内容列表
        TOPIC_LIST: 'question/circletopiclist',
        //获取专家问题列表
        EXPERT_LIST: 'question/expertlist',
        //获取社区话题问题列表
        COMMUNITY_TOPIC_LIST: 'question/communitytopiclist',
        //获取社区问题列表
        COMMUNITY_LIST: 'question/communitylist',
        //获取动态列表
        DYNAMIC_LIST: 'question/dynamiclist',
        //添加专家提问
        ADD_EXPERT_ASK: 'question/addexpert',
        //添加社区提问
        ADD_COMMUNITY_ASK: 'question/addcommunity',
        //添加话题内容
        ADD_TOPIC_ASK: 'question/addcircletopic',
        //新增社区话题问题
        ADD_COMMUNITY_TOPIC_ASK: 'question/addcommunitytopic',
        //添加个人动态
        ADD_DYNAMIC_ASK: 'question/adddynamic',
        //添加课程评论
        ADD_COURSE_ASK: 'question/addcourse',
        //添加圈子问题
        ADD_CIRCLE_ASK: 'question/addcircle',
        //查看问题详情
        QUESTION_VIEW: 'question/view',
        //回复列表
        ANSWER_LIST: 'answer/list',
        //删除回复
        DELETE_ANSWER: 'answer/delete',
        //添加回复
        ADD_ANSWER: 'answer/answer',
        //获取课程所有用户和问题
        COURSE_U_Q_COUNT: 'question/allcourselist',
        //专家社区回复
        EXPERT_COMMUNITY_REPLY: 'question/expertcommunitylist',
        //添加+1操作
        ADD_NUM: 'commonstatistics/addnum',
        //减一-1操作
        DELETE_NUM: 'commonstatistics/deletenum',
        //专家未回复
        NO_RESOLVE: 'question/expertnoresolvelist',
        //专家已回复
        RESOLVE: 'question/expertresolvelist'
    },

    //关键字
    KEYWORD: {
        LIST: 'commonkey/list',
        LIST_TREE: 'commonkey/listtree',
        ADD: 'commonkey/add',
        EDIT: 'commonkey/edit',
        DELETE: 'commonkey/delete',
        VIEW: 'commonkey/view',
    },

    CONTENT_OPERATION: {
        //圈子话题用户总数
        T_U_COUNT_LIST: 'question/allcirclelist',
        //社区话题
        COMMUNITY_TOPIC: 'topic/communitylist',
    },
    // 节点指标
    TARGET: {
        ADD: 'processtarget/add',
        LIST: 'processtarget/list',
        DELETE: 'processtarget/delete',

    },


    /*开放平台 */
    APP: {
        LIST: 'apiapply/list'
    },
    //推送
    PUSH: {
        MESSAGE_LIST: 'common/listpushinfo',
        PUSH_MSG: 'common/pushmsg'
    },

    //区域管理
    AREA_MANAGE: {
        LIST: 'addresarea/list'
    },

    //分类管理
    SORT_MANAGE: {
        LIST: 'syscategory/list',
        ADD: 'syscategory/add',
        EDIT: 'syscategory/edit',
        OPEN: 'syscategory/open',
        STOP: 'syscategory/stop',
        DELETE: 'syscategory/delete'
    },

    //消息管理
    MSG_MANAGE: {
        LIST: 'commonnotice/list',
        ADD: 'commonnotice/add',
        EDIT: 'commonnotice/edit',
        DELETE: 'commonnotice/delete',
        SEND: 'commonnotice/send',
        VIEW: 'commonnotice/view',
    },

    //专家分类
    EXPERT_SORT: {
        LIST: 'expertCategory/syslist',
        ADD: 'expertCategory/sysadd',
        EDIT: 'expertCategory/edit',
        DELETE: 'expertCategory/delete',
    },

    //专家管理
    EXPERT_MANAGE: {
        CLASS_LIST: 'expertCategory/syslist',
        CLASS_NEW: 'expertCategory/sysadd',
        CLASS_DEL: 'expertCategory/delete',
        CLASS_EDIT: 'expertCategory/edit',
        CLASS_STATUS_CHANGE: 'expertCategory/updatestatus',
        SYS_LIST: 'sysexpert/list',
        SYS_ADD: 'sysexpert/add',
        SYS_DELETE: 'sysexpert/delete',
        SYS_EDIT: 'sysexpert/edit',
        SYS_STATUS: 'sysexpert/updatestatus',
        SYS_ACCOUNT: 'sysexpert/addaccount',

        SELF_EXPORT_ADD: 'selfexpert/add',
        SELF_EXPORT_LIST: 'selfexpert/list',
        SELF_EXPORT_DELETE: 'selfexpert/delete',
    },

    // 语音
    VOICE: {
        VOICE_LIST: 'common/voice/list',
        VOICE_ADD: 'common/voice/add',
        VOICE_EDIT: 'common/voice/edit',
        VOICE_DEL: 'common/voice/delete',
        VOICE_STOP: 'common/voice/stop',
        VOICE_OPEN: 'common/voice/open',
    },

    //等级规则
    HIERARCHICAL_RULES: {
        LIST: 'commonlevel/list',
        ADD: 'commonlevel/add',
        DELETE: 'commonlevel/delete',
        EDIT: 'commonlevel/edit'
    },

    //企业注册
    COMPANY_REGISTER: {
        LIST: 'companyregister/list',
        ADD: 'companyregister/add',
        EDIT: 'companyregister/edit',
        REVIEW: 'companyregister/reviewed',
        VIEW: 'companyregister/view',
        DELETE: 'companyregister/delete'
    },

    //打卡记录
    CLOCK_IN_RECORD: {
        LIST: 'clockinrecord/list'
    },

    //生产反馈
    PRODUCE_FEEDBACK: {
        LIST: 'question/productionfeedbacklist',
        ADD: 'question/addproductionfeedback',
        DELETE: 'question/delete',
        VIEW: 'question/view',
        ANSWER_ADD: 'answer/answer',
    },

    // 溯源产品
    TRACE_PRODUCT: {
        LIST: 'traceplantproduct/list',
        ITEM_ADD: 'traceplantproduct/itemadd',
        ITEM_DELETE: 'traceplantproduct/deleteitem',
        DELETE: 'traceplantproduct/delete',
        EDIT_STATUS: 'traceplantproduct/updatestatus',
        EDIT: 'traceplantproduct/edit',
        ADD: 'traceplantproduct/add'
    },

    // 育种管理
    BREED: {
        LIST: 'tracesow/list',
        ADD: 'tracesow/add',
        EDIT: 'tracesow/edit',
        DELETE: 'tracesow/delete',
        STATUS_EDIT: 'tracesow/updatestatus',
        WAREHOUSING: 'tracesow/addInWarehouse',
        DETAIL: 'tracesow/view',
    },

    // 种养管理
    PLANT: {
        LIST: 'traceplant/list',
        ADD: 'traceplant/add',
        EDIT: 'traceplant/edit',
        DELETE: 'traceplant/delete',
        STATUS_EDIT: 'traceplant/updatestatus'
    },

    // 采收管理
    HARVEST: {
        LIST: 'traceharvest/list',
        ADD: 'traceharvest/add',
        EDIT: 'traceharvest/edit',
        DELETE: 'traceharvest/delete',
        STATUS_EDIT: 'traceharvest/updatestatus',
        WAREHOUSING: 'traceharvest/addInWarehouse'
    },

    //质检管理
    QUALITY: {
        LIST: 'tracequality/list',
        ADD: 'tracequality/add',
        EDIT: 'tracequality/edit',
        DELETE: 'tracequality/delete',
        DETAIL: 'tracequality/details',
        STATUS_EDIT: 'tracequality/updatestatus'
    },

    //加工管理
    MACHINING: {
        LIST: 'traceMachining/list',
        ADD: 'traceMachining/add',
        EDIT: 'traceMachining/edit',
        DELETE: 'traceMachining/delete',
        STATUS_EDIT: 'traceMachining/updatestatus',
        DETAIL: 'traceMachining/details',
        BIND: 'traceApplyQrcode/listUseBind',
        WAREHOUSING: 'traceMachining/addInWarehouse',
        BIND_COUNT: 'traceApplyQrcode/listUseBindCount'
    },

    //源码申请管理
    SOURCE_CODE_APPLY: {
        LIST: 'traceApplyQrcode/list',
        ADD: 'traceApplyQrcode/add',
        EDIT: 'traceApplyQrcode/edit',
        DELETE: 'traceApplyQrcode/delete',
        STATUS_EDIT: 'traceApplyQrcode/updatestatus'
    },

    //源码审核管理
    SOURCE_CODE_REVIEW: {
        LIST: 'auditQrcode/list',
        STATUS_EDIT: 'auditQrcode/updatestatus'
    },

    //源码使用管理
    SOURCE_CODE_USE: {
        LIST: 'traceApplyQrcode/listSuc',
        PRINT: 'traceQrcodeDetail/updatestatus',
        RECYCLE: 'traceQrcodeDetail/reycle',
    },

    //源码打印管理
    SOURCE_CODE_PRINT: {
        LIST: 'printQrcode/list',
        PRINT_RECORD: 'printQrcode/printing'
    },
    //源码查询统计
    QUERY_CODE: {
        LIST: 'queryrecord/listcount',
        COUNT_DATA: 'queryrecord/listproductcount',
        COUNT_DATA_YEAR: 'queryrecord/listproductcountyear',
    },
    //投诉管理
    COMPLAIT: {
        LIST: 'complaint/list',
        REPLY: 'complaint/replyhandle',
        STATUS_EDIT: 'complaint/handle'
    },

    //溯源评论
    SOURCE_COMMENT: {
        LIST: 'reviewinfo/list',
        ADD: 'reviewinfo/add',
    },
    //溯源投诉
    SOURCE_COMPLAINT: {
        ADD: 'complaint/add',
        LIST: 'complaint/list',
        REPLY: 'complaint/replyhandle',
        STATUS_EDIT: 'complaint/handle'
    },

    //农药库
    PESTICIDES_LIB: {
        LIST: 'pesticideslib/list',
        ADD: 'pesticideslib/add',
        DELETE: 'pesticideslib/delete',
        VIEW: 'pesticideslib/view',
        OPEN: 'pesticideslib/open',
        STOP: 'pesticideslib/stop',
        TYPE: "pesticideslib/typelist",
        EDIT: 'pesticideslib/update'
    },
    //化肥库
    FERTILIZER_LIB: {
        LIST: 'fertilizerlib/list',
        ADD: 'fertilizerlib/add',
        DELETE: 'fertilizerlib/delete',
        VIEW: 'fertilizerlib/view',
        OPEN: 'fertilizerlib/open',
        STOP: 'fertilizerlib/stop',
        EDIT: 'fertilizerlib/update',
        TYPE: "fertilizerlib/typelist",
    },
    //农具库
    FARMTOOL_LIB: {
        LIST: 'farmoolslib/list',
        ADD: 'farmoolslib/add',
        DELETE: 'farmoolslib/delete',
        VIEW: 'farmoolslib/view',
        OPEN: 'farmoolslib/open',
        STOP: 'farmoolslib/stop',
        EDIT: 'farmoolslib/update',
        TYPE: "farmoolslib/typelist",
    },

    // 自动策略
    PLOY: {
        // 自动策略列表
        PLOY_LIST: 'strategy/list',
        // 自动策略列表（新）
        POLY_LIST_NEW: 'strategy/detailslist',
        // 新增自动策略
        PLOY_NEW: 'strategy/add',
        // 编辑自动策略
        PLOY_EDIT: 'strategy/edit',
        // 策略改变状态（启停用）
        PLOY_STATUS_CHANGE: 'strategy/updatestatus',
        // 策略删除
        PLOY_DEL: 'strategy/delete',
    },

    //市场报价
    MARKET_PRICE: {
        LIST: 'marketprice/list',
        CITY_LIST: 'marketprice/citylist'
    },
    MAIN: {
        //溯源首页
        SOURCE_MAIN: 'summarizing/gettraceall'
    },

    //园区巡查
    PARK_PATROL: {
        LIST: 'dailypatrol/list',
        STATUS_EDIT: 'dailypatrol/updatestatus',
        PATROL_QUESTION_LIST: "dailyPatrolQuestionquestion/list",
        PROCESS_QUESTION: 'dailyPatrolQuestionquestion/update',
    },

    //农事管理
    FARM_WORK: {
        LIST: 'cropoperation/list',
        ADD: 'cropoperation/add',
        EDIT: 'cropoperation/edit',
        DELETE: 'cropoperation/delete',
        OPEN: 'cropoperation/open',
        STOP: 'cropoperation/stop',
        VIEW: "cropoperation/view"
    },

    //农事任务
    FARM_TASK: {
        LIST: 'farmingtask/list',
        ADD: 'farmingtask/add',
        FINISH: 'farmingtask/done',
        DATE_LIST: 'farmingtask/datelist',
        VIEW: 'farmingtask/view',
        DELETE: 'farmingtask/delete',
        EDIT: 'farmingtask/update'
    },

    //农事日报
    FARM_DAILY: {
        LIST: 'farmingdaily/list',
        ADD: 'farmingdaily/add',
        SUBMIT: 'farmingdaily/submit',
        DELETE: 'farmingdaily/delete',
        VIEW: 'farmingdaily/view',
        EDIT: 'farmingdaily/update'
    },

    // 生产计划
    CROP_PLAN: {
        LIST: 'cropplan/list',
        ADD: 'cropplan/add',
        EDIT: 'cropplan/edit',
        DELETE: 'cropplan/delete',
        START: 'cropplan/open',
        END: 'cropplan/stop',
    },
    // 作物管理
    CROP: {
        LIST: 'cropproduct/list',
        VIEW: 'cropproduct/view',
        ADD: 'cropproduct/add',
        COPY: 'cropproduct/copyproduct',
        EDIT: 'cropproduct/edit',
        DELETE: 'cropproduct/delete',
        DIAGNOSETARGET: 'cropproduct/bind'
    },
    // 作物生长周期
    CROP_PROCESS: {
        LIST: 'cropproductcycle/list',
        ADD: 'cropproductcycle/add',
        EDIT: 'cropproductcycle/edit',
        DELETE: 'cropproductcycle/delete',
        SHOW: 'cropgrowcustom/show'
    },
    // 作物监测管理
    CROPTARGET: {
        LIST: 'cropproducttarget/list',
        ADD: 'cropproducttarget/add',
        EDIT: 'cropproducttarget/edit',
        DELETE: 'cropproducttarget/delete',
        NEW_LIST: 'cropproducttarget/getbyarea',
        ARER_TREE: 'cropproducttarget/getareabyvar'
    },

    // 作物品种
    CROP_VARIETY: {
        ADD: 'cropvarieties/add',
        LIST: 'cropvarieties/list',
        EDIT: 'cropvarieties/edit',
        DELETE: 'cropvarieties/delete',
        VIEW: 'cropvarieties/view',
        COPY: 'cropvarieties/copyvarieties',
    },

    //产品质量检测
    RISE_DETECTION: {
        ADD: 'productquality/add',
        LIST: 'productquality/list',
        UPDATE: 'productquality/update',
        DELETE: 'productquality/delete',
        VIEW: 'productquality/view'
    },

    //监测类型(数据同步)
    SYNCDEVICEVALUE: {
        ADD: 'syncdevicevalue/add',
        LIST: 'syncdevicevalue/list',
        UPDATE: 'syncdevicevalue/update',
        DELETE: 'syncdevicevalue/delete',
        DETAIL: 'syncdevicevalue/details',
    },

    // 作物模型
    MODEL: {
        LIST: 'cropgrowcustom/list',
        ADD: 'cropgrowcustom/add',
        EDIT: 'cropgrowcustom/edit',
        DELETE: 'cropgrowcustom/delete',
        SHOW: 'cropgrowcustom/show',
        COPY: 'cropgrowcustom/copycustom',
    },

    // 模型年产量
    YEARLY_OUTPUT: {
        LIST: 'cropgrowcustomyear/list',
        ADD: 'cropgrowcustomyear/add',
        EDIT: 'cropgrowcustomyear/edit',
        DELETE: 'cropgrowcustomyear/delete'
    },
    // 模型周期
    MODEL_PROCESS: {
        LIST: 'cropgrowcycle/list',
        ADD: 'cropgrowcycle/add',
        EDIT: 'cropgrowcycle/edit',
        DELETE: 'cropgrowcycle/delete'
    },
    // 周期事项
    MODEL_PROCESS_INSTRUCTION: {
        LIST: 'cropgrowcycleitem/list',
        ADD: 'cropgrowcycleitem/add',
        EDIT: 'cropgrowcycleitem/edit',
        DELETE: 'cropgrowcycleitem/delete'
    },
    // 诊断指标
    DIAGNOSE: {
        LIST: 'cropdiagnosis/list',
        ADD: 'cropdiagnosis/add',
        EDIT: 'cropdiagnosis/edit',
        DELETE: 'cropdiagnosis/delete',
    },
    // 诊断指标详情
    DIAGNOSEITEM: {
        LIST: 'cropdiagnosisitem/list',
        ADD: 'cropdiagnosisitem/add',
        EDIT: 'cropdiagnosisitem/edit',
        DELETE: 'cropdiagnosisitem/delete',
    },

    //图片识别记录
    IMAGE_IDENTIFY_RECORD: {
        LIST: 'imageidentify/list',
        ADD: 'imageidentify/add',
        VIEW: 'imageidentify/view',
        DELETE: 'imageidentify/delete'
    },

    //图片识别
    IMAGE_IDENTIFY: {
        PLANT: 'baidudetect/plantDetect',
        ANIMAL: 'baidudetect/animalDetect',
        FAV: 'baidudetect/ingredientDetect',
        MULTIPLE: 'baidudetect/multiObjectDetect',
    },

    //首页API
    HOME: {
        //物联网首页
        IOT_HOME: 'summarizing/getiothome',
        //物联网设备操作日志
        IOT_OPERATION_LOG: 'farmcontrol/loglist',
        //溯源首页
        TRACE_HOME: 'summarizing/gettraceall',
        //生产首页
        PRODUCE_HOME: 'summarizing/getall',
        //运营首页
        OPERATE_HOME: 'summarizing/getoperationhome',
        //内容首页
        CONTENT_HOME: 'summarizing/contenthome'
    },
    // 数据爬虫
    REPTILE: {
        ADD: 'collectionsites/add',
        EDIT: 'collectionsites/update',
        DELETE: 'collectionsites/delete',
        LIST: 'collectionsites/list',
        UPDATESTATUE: 'collectionsites/updatestatus',

    },
    // 爬虫节点
    REPTILR_NODE: {
        ADD: 'collectionnewsnode/add',
        EDIT: 'collectionnewsnode/update',
        LIST: 'collectionnewsnode/list',
        DELETE: 'collectionnewsnode/delete',
        //新增产品定时任务
        P_TIMING: 'job/addproduct',
        //新增新闻定时任务
        N_TIMING: 'job/addnews',
        TIMING_OPEN: 'job/open',
        TIMING_STOP: 'job/stop',
        TIMING_DELETE: 'job/delete'
    },

    // 物料
    MATERIAL: {
        LIST: 'product/lists',
        ADD: 'product/add',
        EDIT: 'product/edit',
        DELETE: 'product/delete',
        STATUS: 'product/updatestatus',
        VIEW: 'product/view',
        // 管理属性
        SET_ATTRIBUTE: 'productrelation/add',
        // 物料属性列表
        ATTRIBUTE: 'productrelation/list'
    },

    //标签库
    TAG: {
        //用户标签库
        USER_LIST: 'applytaguser/list',
        USER_ADD: 'applytaguser/add',
        USER_EDIT: 'applytaguser/edit',
        USER_VIEW: 'applytaguser/view',
        USER_DELETE: 'applytaguser/delete',
        USER_OPEN: 'applytaguser/open',
        USER_STOP: 'applytaguser/stop',

        //系统标签库
        SYS_LIST: 'applytag/list',
        SYS_ADD: 'applytag/addinfo',
        SYS_EDIT: 'applytag/editinfo',
        SYS_VIEW: 'applytag/view',
        SYS_DELETE: 'applytag/delete',
        SYS_OPEN: 'applytag/open',
        SYS_STOP: 'applytag/stop',

        //配置标签库
        CONFIG_LIST: 'applytagconfig/list',
        CONFIG_VIEW: 'applytagconfig/view',
        CONFIG_DELETE: 'applytagconfig/delete',
        CONFIG_OPEN: 'applytagconfig/open',
        CONFIG_STOP: 'applytagconfig/stop',
    },

    //平台配置
    APPLY: {
        LIST: 'apply/list',
        ADD: 'apply/add',
        EDIT: 'apply/update',
        DELETE: 'apply/delete',
        OPEN: 'apply/open',
        STOP: 'apply/stop',
        VIEW: 'apply/view'
    },

    //菜单配置
    MENU: {
        LIST: 'applymenu/list',
        LIST_TREE: 'applymenu/treelist',
        ADD: 'applymenu/add',
        ROLE_AUTHORITY: 'applymenu/relationlist',
        USER_AUTHORITY: 'applymenu/applylist',
        MENU_TREE: 'applymenu/menutree',
        DELETE: 'applymenu/delete',
        DELETE_MENU: 'applymenu/deletemenu',
        EDIT: 'applymenu/update',
        MENU_LIST: 'applymenu/menulist',
        RELATION_MENU: 'applymenu/relationmenu',
        // 绑定角色菜单
        SET_ROLE_MENU: 'applymenurelation/bind',
        // 角色已有平台
        ROLE_APPLICATION: 'applymenurelation/applylist',
        // 角色已有平台
        APPLICATIONS: 'applymenurelation/applylistbyroleid',
        // 设置别名
        ALIGN_NAME_ADD: 'applymenuname/add',
        ALIGN_NAME_EDIT: 'applymenuname/update',

    },

    //设备网关
    DEVICE_GATEWAY: {
        LIST: 'devicegateway/list',
        ADD: 'devicegateway/add',
        EDIT: 'devicegateway/edit',
        EDIT_STATUS: 'devicegateway/updatestatus',
        DELETE: 'devicegateway/delete'
    },
    //二维码模板
    QRCODE_MOULD: {
        LIST: 'commonqrcodetemplate/list',
        ADD: 'commonqrcodetemplate/add',
        EDIT: 'commonqrcodetemplate/update',
        DELETE: 'commonqrcodetemplate/delete',
        OPEN: 'commonqrcodetemplate/open',
        STOP: 'commonqrcodetemplate/stop',
    },
    //二维码溯源展示
    QRCODE_TRACE: {
        LIST: 'qrcodetemplate/list',
        ADD: 'qrcodetemplate/add',
        EDIT: 'qrcodetemplate/update',
        DELETE: 'qrcodetemplate/delete',
        VIEW: 'qrcodetemplate/view'
    },
    // 溯源
    TRACE: {
        SEARCH: 'traceQuery/query'
    },

    // 复购配置
    REBUY: {
        LIST: 'tracerebuy/list',
        ADD: 'tracerebuy/add',
        EDIT: 'tracerebuy/update',
        DELETE: 'tracerebuy/delete',
        VIEW: 'tracerebuy/view',
    },

    // 虫情灯类型
    INSECTTYPE: {
        ADD: 'insecttype/add',
        EDIT: 'insecttype/edit',
        DELETE: 'insecttype/delete',
        LIST: 'insecttype/list',
    },
    // 虫情灯列表
    INSECT: {
        ADD: 'insectmanage/add',
        EDIT: 'insectmanage/edit',
        DELETE: 'insectmanage/delete',
        LIST: 'insectmanage/list',
    },

    //监测类型
    // SOLICAT:{
    //     LIST:'soilcat/list',
    //     ADD:'soilcat/add',
    //     EDIT:"soilcat/edit",
    //     VIEW:"soilcat/view",
    //     STOP:"soilcat/stop",
    //     OPEN:"soilcat/open",
    //     DELETE:"soilcat/delete"
    // },

    //监测类型
    SOLICAT: {
        LIST: 'dictconfig/list',
        CHILD: "dictconfig/childlist",
        ADD: 'dictconfig/add',
        UPDATE: "dictconfig/update",
        VIEW: "dictconfig/view",
        STOP: "dictconfig/stop",
        OPEN: "dictconfig/open",
        DELETE: "dictconfig/delete",
    },

    //授权场景
    REPORT: {
        LIST: 'reportdata/list',
        ADD: 'reportdata/add',
        CANCEL: 'reportdata/cancel',
        SCENE_LIST: 'reportdata/checklist',
    },
    //专家粉丝
    EXPERT_FANS: {
        LIST: 'sysexpert/myfans',
        //授权给专家的场景列表
        SCENE_LIST: 'reportdata/myscence',
        //知识检索
        KNOWLEDGE_SEARCH: 'pest/serachlist',
        //关键词记录
        KEY_WORD: 'pest/searchrecord'
    }

}

export default API;