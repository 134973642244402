import axios from 'axios';
import API, { baseUrl } from './api';
import { LoadingBar, Message } from 'view-design';

let instance = axios.create({
    baseURL: baseUrl.URL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000,
})

instance.interceptors.request.use(config => {

    if (!config.data) {
        config.data = {
        }
    }
    if (config.data.apiVersion) {
        config.baseURL = baseUrl[config.data.apiVersion]
        delete config.data.apiVersion
    }
    if (config.url == API.USER.USER_LOGIN_INFO_IMG) {
        LoadingBar.start();
        return config;
    }
    if (!config.data.userId) {
        config.data.userId = localStorage.userId || '';
    }
    if (!config.data.companyNo) {
        config.data.companyNo = localStorage.companyNo || '';
    }
    for (let key in config.data) {
        if (config.data[key] === "") {
            delete config.data[key];
        }
    }
    config.headers.common['Authorization'] = `Bearer_${localStorage.token}`;

    LoadingBar.start();
    return config;
})

instance.interceptors.response.use(response => {
    LoadingBar.finish();
    if (response.data.code == '200') {
        return response.data.data;
    };
    if (response.config.url == API.USER.APPLICATION || response.config.url == API.USER.MENU) {
        return response.data.data;
    }
    Message.error({ content: response.data?.message ?? '请求好像出错了，请重新尝试', duration: 1.5 });
    return Promise.reject(response.data)
}, e => {
    LoadingBar.error();
    return Promise.reject(e)
})


export const post = instance.post;